.register{
    /* background: -webkit-linear-gradient(left, #3931af, #00c6ff); */
    /* margin-top: 3%; */
    padding: 3%;
    height: 100vh;
    z-index: 99;
}
.register-left{
    text-align: center;
    color: #fff;
    margin-top: 4%;
}
.register-left input{
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    width: 60%;
    background: #f8f9fa;
    font-weight: bold;
    color: #383d41;
    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer;
}
.register-right{
    background: #f8f9fa;
    border-top-left-radius: 10% 50%;
    border-bottom-left-radius: 10% 50%;
    height: 85vh;
}
.register-left img{
    margin-top: 15%;
    margin-bottom: 5%;
    width: 25%;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
.auth-logo{
    width: 200px;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
.register-left p{
    font-weight: lighter;
    padding: 12%;
    margin-top: -9%;
}
.register .register-form{
    padding: 10%;
    margin-top: 10%;
}
.btn-register, .btn-login,
.btn-register:active, .btn-login:active{
    /* float: right; */
    border: 1px solid #0062cc ;
    color: #00c6ff !important;
    border-radius: 1.5rem;
    padding: 6px 50px;
    background: #FFF !important;
    /* color: #fff; */
    font-weight: 600;
    /* width: 50%; */
    cursor: pointer;
}
/* .btn-login{
    /* float: right; */
    /* margin-top: 10%; *
    border: none;
    border-radius: 1.5rem;
    padding: 6px 50px;
    background: #0062cc !important;
    color: #fff;
    font-weight: 600;
    /* width: 50%; *
    cursor: pointer;
} */
.btn-login:hover, .btn-register:hover{
    /* border: 1px solid #0062cc !important; */
    /* color: #0062cc !important; */
    opacity: .5;
    background: #f8f9fa !important;
}
.nav-link.active:hover{
    color: #fff !important;
    background-color: #00c6ff !important;
}
.login-card{
    margin: 50px;
    margin-top:50px;
}
.register .nav-tabs{
    margin-top: 3%;
    border: none;
    background: #0062cc;
    border-radius: 1.5rem;
    width: 28%;
    float: right;
}
.register .nav-tabs .nav-link{
    padding: 3%;
    height: 34px;
    font-weight: 600;
    color: #fff;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover{
    border: none;
}
.register .nav-tabs .nav-link.active{
    width: 100px;
    /* color: #0062cc; */
    /* border: 2px solid #0062cc; */
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}
.register-heading{
    text-align: center;
    margin-top: 8%;
    margin-bottom: -15%;
    color: #495057;
}
@media only screen and (max-width: 600px) {
    .login-card{
        margin: 50px 10px 10px 10px;
        margin-top: 50px;
    }
}