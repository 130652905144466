@media only screen and (min-width: 768px) {
    .mobile-head {
        display: none;
    }

    .reg-col {
        height: 100vh;
        overflow-y: scroll;
    }

    .reg-card {
        border-radius: 1em;
        height: 94vh;
        margin-top: 30px;
        border: none;
        background-color: (rgb(96, 14, 230));
        /* background-image: linear-gradient(rgb(96, 14, 230)); */
    }

    .reg-card1 {
        border-radius: 1em;
        height: 67vh;
        margin-top: 30px;
        border: none;
        background-color: (rgb(96, 14, 230));
        /* background-image: linear-gradient(rgb(96, 14, 230)); */
    }

    .society-logo {
        display: none;
    }
}


.bits-head {
    color: white;
    font-weight: bold;
    font-size: 500%;
}

.bits-reg {
    color: white;
    font-weight: bold;
    font-size: 2em;
}

.title-wrap {
    position: relative;
    top: 30%;
    color: white;
    /* text-align: center; */
}

.goto {
    /* background-color: rgb(23, 162, 184); */

    background-color: rgb(96, 14, 230);

    color: white;
    padding: 0.5em;
    border: none;
    width: 10em;
    border-radius: 2em;
    cursor: pointer;
}

.login-col {
    background-color: rgb(96, 14, 230);
}




.login-form {
    position: relative;
    top: 15%;
    bottom: -15%;
}

.login-card {
    border-radius: 1em;
    /* border-bottom-left-radius: 4em;
    border-top-right-radius: 4em;
    border-bottom-right-radius: 0em; */
    border: none;
    background-color: (rgb(96, 14, 230));
    /* background-image: linear-gradient(to bottom right, rgb(184, 168, 209)); */
    /* background-image: linear-gradient(to bottom right, rgb(255, 255, 255), rgb(127, 205, 218)); */

}



.reg-card ::-webkit-scrollbar {
    display: none;
}

.login-field {
    width: 100%;
    margin-top: 1em;
    border-radius: .5em;
    border: none;
    background-color: rgb(245, 245, 245);
    height: 2.5em;
    padding: 1em;
}

.login-text {
    /* text-align: center; */
    font-weight: bold;
    margin: 0;
    color: grey;
    font-size: 2em;
}

/* ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: rgb(196, 196, 196); 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } */
.reg-sub-head {
    color: grey;
}

.form-group {
    transition: all 0.3s;
}

/* .form-label {
    display: block;
    opacity: 1;
    transform: translateY(-2rem);
    transform-origin: 0 0;
    transition: all 0.3s;
    padding: 0 8px;
} */

.form-control {
    color: grey;
    border-radius: 5px;
    width: 100%;
    transition: all 0.3s;
    transition: outline 0s;
    padding: 8px;
    box-shadow: none;
    background-color: rgb(230, 230, 230);
    border: none;
}

.form-control::placeholder {
    color: grey;
}

.form-control:focus {
    box-shadow: none;
    outline: none;
    background-color: rgb(230, 230, 230);
    /* color: grey; */
}

/* .form-control:focus+.form-label, .form-control:not(:placeholder-shown)+.form-label {
    transform: translateY(-4rem) scale(0.75) translateX(0px);
} */

.sel {
    outline: none;
    background-color: rgb(230, 230, 230);
    border: none;
    width: 100%;
    border-radius: 5px;
    padding: 11px;
    color: grey;
}

.auth-btn {
    background-color: white;
    border: 1px solid rgb(96, 14, 230);
    border-radius: 30px;
    color: rgb(96, 14, 230);
    padding: 8px;
    font-weight: bold;
    width: 40%;
    cursor: pointer;
}

.auth-btn:hover {
    background-color: rgb(218, 213, 225);
    color: rgb(255, 255, 255);
    /* transition: ; */
}

.societyImage {
    /* border: 2px solid rgb(96, 14, 230); */
    border-radius: 10px;
}

.img-col {
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

@media only screen and (max-width: 768px) {

    .login-div {
        background-color: rgb(96, 14, 230);
        margin: 0;
        padding: 0;
        height: 100%;
        overflow: hidden;
    }

    .img-col {
        /* visibility: hidden; */
        display: none;
        /* height: 100vh; */
        /* margin-bottom: 0; */
    }

    .login-form {
        height: 100vh;
    }

    .login-form {
        position: relative;
        top: 0;
        bottom: 0;
    }

    .login-card {
        border-radius: 1em;
        /* border-bottom-left-radius: 4em;
        border-top-right-radius: 4em;
        border-bottom-right-radius: 0em; */
        border: none;
        margin-top: 0;
        padding-top: 0;
        background-color: (rgb(96, 14, 230));
        /* background-image: linear-gradient(to bottom right, rgb(184, 168, 209)); */
        /* background-image: linear-gradient(to bottom right, rgb(255, 255, 255), rgb(127, 205, 218)); */

    }

    .login-row {
        margin-bottom: 0;
        background-color: rgb(96, 14, 230);
    }

    .bits-head {
        color: white;
        font-weight: bold;
        font-size: 300%;
        text-align: center;
    }

    .bits-slo {
        color: white;
        /* font-weight: bold; */
        text-align: center;
    }

    .reg-card {
        border-radius: 1em;
        margin-top: 0;
        border: none;
        background-color: (rgb(96, 14, 230));
    }

    .register-left {
        display: none;
    }

    .login-text {
        font-size: 100%;
    }

    .coop-name {
        font-size: 80%;
    }

    .logo-col {
        display: none;
    }
    .society-logo{
        width: 50px;
    }
}