
.brain-in{
    text-align: center;
    margin: auto;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10%;
    /* margin-top: 15px; */
    font-family: Calibri;
}
.log-in-btn{
    border: none;
    background-color: transparent;
    margin-left: -180px;
    margin-bottom: 10px;
}
.Div-one{
    display: flex;
    flex-direction: row;
    text-align: left;
    margin: auto;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 580px; */
    background-color: #fff;
    width: 300px;
    border-bottom: 1px solid #938989;
    padding: 20px;
    padding-bottom: 7;
}
/* a{
    background-color: #fff;
} */
a .p{
    color: #fff;
    text-decoration: none;
    text-transform: none;
    background-color: rgb(96, 14, 230);
    padding: 15px;
    font-size: 12px;
    padding-right: 18px;
    padding-left: 18px;
    border-radius: 50px;
}
a 
a{
    text-decoration: none;
}
.pp{
    font-size: 12px;
    /* margin-left: -125px; */
    margin-top: 5px;
    background-color: #fff;
    text-align: left;
    padding-left: -20px;
}
.divider{
    color: black;
    font-size: 15px;
    margin-left: 20px;
    background-color: #fff;
}
