/* Global CSS, you probably don't need that */

.clfix:after {
  clear: both;
  content: "";
  /* display: block; */
  height: 0;
}

.mother {
  margin: 5px auto;
}

.motherwrapper {
  display: table-cell;
  vertical-align: middle;
}

.mothernav {
  margin-top: 40px;
}

.motherpull-right {
  float: right;
}

/* a, a:active {
      color: #333;
      text-decoration: none;
  }
  
  a:hover {
      color: #999;
  } */

/* Breadcrups CSS */

@media screen and (max-width: 750px) {
  .arrow-steps .step {
    padding: 10px 10px 10px 30px;
    min-width: 100px;
  }
}

@media screen and (max-width: 900px) {
  .arrow-steps .step {
    padding: 5px 5px 5px 15px;
    min-width: 70px;
  }
}

.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #666;
  cursor: default;
  margin: 0 3px;
  padding: 10px 10px 10px 30px;
  min-width: 140px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 17px solid #d9e3f7;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step:last-child:after {
  border: none;
}

.arrow-steps .step:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -12px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #363563;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #363563;
}
