/* Importing fonts from Google */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* Reseting */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Poppins', sans-serif; */
}

body {
    background: #ecf0f3;
}

.login-wrapper {
    max-width: 400px;
    min-height: 500px;
}
.signup-wrapper {
    max-width: 800px;
    min-height: 500px;
}

.signup-wrapper, .login-wrapper{
    margin: 80px auto;
    padding: 40px 30px 30px 30px;
    background-color: #ecf0f3;
    border-radius: 15px;
    box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
}
.logo {
    width: 80px;
    margin: auto;
    padding: 10;
    cursor: pointer;
}

.logo img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    padding: 10;
    box-shadow: 0px 0px 3px #5f5f5f,
        0px 0px 0px 5px #ecf0f3,
        8px 8px 15px #a7aaa7,
        -8px -8px 15px #fff;
}

.login-wrapper .name, .signup-wrapper .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: #555;
}

.login-wrapper .form-field input,
.login-wrapper .form-field textarea {
    width: 100%;
    display: block;
    border: none;
    outline: none;
    background: none;
    font-size: 1.2rem;
    color: #555;
    padding: 10px 15px 10px 10px;
    /* border: 1px solid red; */
}

.login-wrapper .form-field {
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
}

.login-wrapper .form-field .fas {
    color: #555;
}

.login-wrapper .btn ,
.signup-wrapper .btn
{
    box-shadow: none;
    width: 100%;
    height: 40px;
    background-color: #167ebe;
    color: #fff;
    border-radius: 25px;
    box-shadow: 3px 3px 3px #b1b1b1,
        -3px -3px 3px #fff;
    letter-spacing: 1.3px;
}

.login-wrapper .btn:hover {
    background-color: #1DA1F2;
}

.login-wrapper a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #1DA1F2;
}

.login-wrapper a:hover {
    color: #1DA1F2;
}

@media(max-width: 380px) {
    .login-wrapper {
        margin: 30px 20px;
        padding: 40px 15px 15px 15px;
    }
}

.form-group>label {
    bottom: -5px;
    left: 15px;
    position: relative;
    /* background-color: white; */
    font-size: .8em;
    transition: 0.2s;
    pointer-events: none;
  }
  
  .form-control:focus~label {
    bottom: 55px;
  }
  
  .form-control:valid~label {
    bottom: 55px;
  }
  .my-primary-text{
    color: #1DA1F2;
}
.my-primary-btn{
    background-color: #1DA1F2;
}
.code_input{
    width: 40px !important;
    border-radius: 5px;
    border: none;
    padding: 8px;
    margin: 10px;
    background-color: rgb(214, 214, 214);
}